import {useState, useEffect} from "react"
import './Image.css';

export default function Image({ src, link, srcSet = '', placeholder = ''}) {
  const [imageState, setImageState] = useState()

  useEffect(() => {
    if (!imageState) {
      setImageState("loading")
      const image = document.createElement('img')
      image.onload = () => {
        setImageState("loaded")
      }
      image.srcset = srcSet || src;
    }
  }, [imageState, src, srcSet])

  const ImageWrapper = link ? p => <a href={link}>{p.children}</a> : p => <>{p.children}</>

  return (
    <div className='image-container' key={src}>
      <div className="image-wrapper">
        <ImageWrapper>
          <img className={`image-placeholder ${imageState === "loaded" && "hidden"}`}
            src={placeholder} alt="" />
          <img className={`image ${imageState === "loaded" && "show"}`}
            src={src}
            srcSet={srcSet} alt="" />
        </ImageWrapper>
      </div>
    </div>
  )
}