import { Link } from "react-router-dom";

import './Menu.css';

export default function Menu() {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/nature">nature</Link>
        </li>
        <li>
          <Link to="/street">street</Link>
        </li>
        <li>
          <Link to="/people">people</Link>
        </li>
        <li>
          <Link to="/painting">painting</Link>
        </li>
        <li>
          <Link to="/digital">digital</Link>
        </li>
        <li>
          <Link to="/music">music</Link>
        </li>
        <li>
          <Link to="/about">about</Link>
        </li>
      </ul>
      <h1>handeyeco</h1>
    </nav>
  )
}