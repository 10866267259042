import './About.css';

export default function About() {
  return (
    <div className='about'>
      <div className='about__inner'>
        <p>Hey I'm Matthew, but I go by handeyeco when I'm making stuff.</p>
        <p>I do a lot of things:</p>
        <ul>
          <li>I'm a programmer by day</li>
          <li>I enjoy organizing communities</li>
          <li>I make art sometimes:</li>
          <ul>
            <li>I paint</li>
            <li>I make music</li>
            <li>I code digital art</li>
            <li>I take photos</li>
            <li>I repurpose found objects</li>
          </ul>
        </ul>
        <p>I'm passionate about encouraging an open society. My personal code is usually Open Source and my art is usually Creative Commons. I work for a non-profit education company that also contributes to Open Source and the Creative Commons.</p>
        <p>I try to work against censorship and towards dismantling the theocracy.</p>
        <ul>
          <li>Art</li>
          <ul>
            <li><a href='https://www.instagram.com/hand_eye_co/'>Painting</a></li>
            <li><a href='https://www.instagram.com/handeye__co/'>Photos</a></li>
          </ul>
          <li>Music</li>
          <ul>
            <li><a href='https://thedronings.bandcamp.com/'>Old music</a></li>
            <li><a href='https://secondactoverture.bandcamp.com/'>Older music</a></li>
          </ul>
          <li>Code</li>
          <ul>
            <li><a href='https://github.com/handeyeco'>Code</a></li>
            <li><a href='https://handeyeco.github.io/tech-blog/'>Tech blog</a></li>
          </ul>
        </ul>
      </div>
    </div>
  )
}