import { Routes, Route } from 'react-router';
import Gallery from './Gallery';
import About from './About';
import Menu from './Menu';
import galleryData from './gallery-data'

import './App.css';

function App() {
  return (
    <div className="App">
      <Menu />
      <Routes>
        <Route path="/street" element={<Gallery list={galleryData.street} />} />
        <Route path="/people" element={<Gallery list={galleryData.people} />} />
        <Route path="/painting" element={<Gallery list={galleryData.painting} />} />
        <Route path="/digital" element={<Gallery list={galleryData.digital} />} />
        <Route path="/music" element={<Gallery list={galleryData.music} />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<Gallery list={galleryData.nature} />} />
      </Routes>
      <footer>
        Licensed <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/">CC BY-NC-SA</a> unless otherwise specified
      </footer>
    </div>
  );
}

export default App;
