const gallery = {
    nature: [
        {
            src: 'images/wolf.JPG',
            placeholder: 'images/wolf__p-100w.jpg',
            srcSet: 'images/wolf__p-2000w.jpg 2000w, images/wolf__p-4000w.jpg 4000w',
        },
        {
            src: 'images/diving.JPG',
            placeholder: 'images/diving__p-100w.jpg',
            srcSet: 'images/diving__p-2000w.jpg 2000w, images/diving__p-4000w.jpg 4000w',
        },
        {
            src: 'images/water-snake.JPG',
            placeholder: 'images/water-snake__p-100w.jpg',
            srcSet: 'images/water-snake__p-2000w.jpg 2000w, images/water-snake__p-4000w.jpg 4000w',
        },
        {
            src: 'images/bearing.JPG',
            placeholder: 'images/bearing__p-100w.jpg',
            srcSet: 'images/bearing__p-2000w.jpg 2000w, images/bearing__p-4000w.jpg 4000w',
        },
        {
            src: 'images/dean.JPG',
            placeholder: 'images/dean__p-100w.jpg',
            srcSet: 'images/dean__p-2000w.jpg 2000w, images/dean__p-4000w.jpg 4000w',
        },
        {
            src: 'images/bullets.JPG',
            placeholder: 'images/bullets__p-100w.jpg',
            srcSet: 'images/bullets__p-2000w.jpg 2000w, images/bullets__p-4000w.jpg 4000w',
        },
    ],
    street: [
        {
            src: 'images/alpha.JPG',
            placeholder: 'images/alpha__p-100w.jpg',
            srcSet: 'images/alpha__p-2000w.jpg 2000w, images/alpha__p-4000w.jpg 4000w',
        },
        {
            src: 'images/loom.JPG',
            placeholder: 'images/loom__p-100w.jpg',
            srcSet: 'images/loom__p-2000w.jpg 2000w, images/loom__p-4000w.jpg 4000w',
        },
        {
            src: 'images/microfilm.JPG',
            placeholder: 'images/microfilm__p-100w.jpg',
            srcSet: 'images/microfilm__p-2000w.jpg 2000w, images/microfilm__p-4000w.jpg 4000w',
        },
        {
            src: 'images/alien.JPG',
            placeholder: 'images/alien__p-100w.jpg',
            srcSet: 'images/alien__p-2000w.jpg 2000w',
        },
        {
            src: 'images/roller.JPG',
            placeholder: 'images/roller__p-100w.jpg',
            srcSet: 'images/roller__p-2000w.jpg 2000w, images/roller__p-4000w.jpg 4000w',
        },
        {
            src: 'images/graphic.JPG',
            placeholder: 'images/graphic__p-100w.jpg',
            srcSet: 'images/graphic__p-2000w.jpg 2000w, images/graphic__p-4000w.jpg 4000w',
        },
        {
            src: 'images/open.JPG',
            placeholder: 'images/open__p-100w.jpg',
            srcSet: 'images/open__p-2000w.jpg 2000w, images/open__p-4000w.jpg 4000w',
        },
    ],
    people: [
        {
            src: 'images/tarot.JPG',
            placeholder: 'images/tarot__p-100w.jpg',
            srcSet: 'images/tarot__p-2000w.jpg 2000w, images/tarot__p-4000w.jpg 4000w',
        },
        {
            src: 'images/exit.JPG',
            placeholder: 'images/exit__p-100w.jpg',
            srcSet: 'images/exit__p-2000w.jpg 2000w, images/exit__p-4000w.jpg 4000w',
        },
        {
            src: 'images/pena.JPG',
            placeholder: 'images/pena__p-100w.jpg',
            srcSet: 'images/pena__p-2000w.jpg 2000w, images/pena__p-4000w.jpg 4000w',
        },
        {
            src: 'images/pool.JPG',
            placeholder: 'images/pool__p-100w.jpg',
            srcSet: 'images/pool__p-2000w.jpg 2000w, images/pool__p-4000w.jpg 4000w',
        },
        {
            src: 'images/queen.JPG',
            placeholder: 'images/queen__p-100w.jpg',
            srcSet: 'images/queen__p-2000w.jpg 2000w, images/queen__p-4000w.jpg 4000w',
        },
        {
            src: 'images/crystals.JPG',
            placeholder: 'images/crystals__p-100w.jpg',
            srcSet: 'images/crystals__p-2000w.jpg 2000w, images/crystals__p-4000w.jpg 4000w',
        },
        {
            src: 'images/cherokee.JPG',
            placeholder: 'images/cherokee__p-100w.jpg',
            srcSet: 'images/cherokee__p-2000w.jpg 2000w, images/cherokee__p-4000w.jpg 4000w',
        },
        {
            src: 'images/floor.JPG',
            placeholder: 'images/floor__p-100w.jpg',
            srcSet: 'images/floor__p-2000w.jpg 2000w, images/floor__p-4000w.jpg 4000w',
        },
        {
            src: 'images/rope.JPG',
            placeholder: 'images/rope__p-100w.jpg',
            srcSet: 'images/rope__p-2000w.jpg 2000w, images/rope__p-4000w.jpg 4000w',
        },
        {
            src: 'images/xmas.JPG',
            placeholder: 'images/xmas__p-100w.jpg',
            srcSet: 'images/xmas__p-2000w.jpg 2000w, images/xmas__p-4000w.jpg 4000w',
        },

    ],
    painting: [
        {
            src: 'images/thinking.JPG',
            placeholder: 'images/thinking__p-100w.jpg',
            srcSet: 'images/thinking__p-2000w.jpg 2000w',
        },
        {
            src: 'images/smoking.JPG',
            placeholder: 'images/smoking__p-100w.jpg',
            srcSet: 'images/smoking__p-2000w.jpg 2000w',
        },
        {
            src: 'images/color_portrait.JPG',
            placeholder: 'images/color_portrait__p-100w.jpg',
            srcSet: 'images/color_portrait__p-2000w.jpg 2000w',
        },
        {
            src: 'images/abstract.JPG',
            placeholder: 'images/abstract__p-100w.jpg',
            srcSet: 'images/abstract__p-2000w.jpg 2000w',
        },
        {
            src: 'images/bw_portrait.JPG',
            placeholder: 'images/bw_portrait__p-100w.jpg',
            srcSet: 'images/bw_portrait__p-2000w.jpg 2000w',
        },

    ],
    digital: [
        {
            src: 'images/databend_photo.JPG',
            placeholder: 'images/databend_photo__p-100w.jpg',
            srcSet: 'images/databend_photo__p-2000w.jpg 2000w',
        },
        {
            src: 'images/bent1.png',
            placeholder: 'images/bent1__p-100w.png',
        },
        {
            src: 'images/bent3.png',
            placeholder: 'images/bent3__p-100w.png',
            srcSet: 'images/bent3__p-2000w.png 2000w',
        },
        {
            src: 'images/hex.png',
            placeholder: 'images/hex__p-100w.png',
            link: 'https://handeyeco.github.io/Hexagon/',
        },
        {
            src: 'images/triangles.png',
            placeholder: 'images/triangles__p-100w.png',
            link: 'https://handeyeco.github.io/Triangle/',
        },
    ],
    music: [
        {
            src: 'images/aabel.jpg',
            placeholder: 'images/aabel__p-100w.jpg',
            date: '2014-06-17',
            caption: 'A & Abel',
            link: 'https://thedronings.bandcamp.com/album/a-abel',
        },
        {
            src: 'images/lcd.jpg',
            placeholder: 'images/lcd__p-100w.jpg',
            date: '2012-05-08',
            caption: 'LCD',
            link: 'https://thedronings.bandcamp.com/album/lcd',
        },
        {
            src: 'images/fortorching.jpg',
            placeholder: 'images/fortorching__p-100w.jpg',
            date: '2010-05-01',
            caption: 'For Torching',
            link: 'https://thedronings.bandcamp.com/album/for-torching',
        },
        {
            src: 'images/nothingwrong.jpg',
            placeholder: 'images/nothingwrong__p-100w.jpg',
            date: '2010-02-01',
            caption: 'Nothing Wrong / Forest Fire',
            link: 'https://thedronings.bandcamp.com/album/nothing-wrong-forest-fire',
        },
        {
            src: 'images/cityofpower.jpg',
            placeholder: 'images/cityofpower__p-100w.jpg',
            date: '2010-05-01',
            caption: 'City of Power: Experiments in Sound',
            link: 'https://thedronings.bandcamp.com/album/city-of-power-experiments-in-sound',
        },
        {
            src: 'images/robotmusic.jpg',
            placeholder: 'images/robotmusic__p-100w.jpg',
            date: '2007-10-03',
            caption: 'Robot Music',
            link: 'https://secondactoverture.bandcamp.com/album/robot-music',
        },
        {
            src: 'images/odetostrings.jpg',
            placeholder: 'images/odetostrings__p-100w.jpg',
            date: '2007-05-19',
            caption: 'Ode to Strings',
            link: 'https://secondactoverture.bandcamp.com/album/ode-to-strings',
        },

    ]
}

export default gallery